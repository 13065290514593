const widgetEndpoints = {
  totalEnergyUse: 'v0/energy-usage-summary/',
  totalEnergyUseV1: 'v1/energy-usage-summary/',
  processEnergyUse: 'v0/process-energy-usage-summary/',
  instantaneousPower: 'v0/energy-usage/',
  processInstantaneousPower: 'v1/equipment-instantaneous-power/',
  processEnergyUseZoneLevel: 'v0/zonal-energy-usage-summary/'
};
const widgetEndpointsLayers = {
  totalEnergyUse: 'v0/layers/energy-usage-summary/',
  totalEnergyUseV1: 'v1/energy-usage-summary/',
  processEnergyUse: 'v0/layers/process-energy-usage-summary/',
  instantaneousPower: 'v0/layers/energy-usage/',
  processInstantaneousPower: 'v0/layers/equipment-instantaneous-power/',
  processEnergyUseZoneLevel: 'v0/zonal-energy-usage-summary/'
};

const customWidgetEndpoints = {
  energyUseSummary: 'v0/custom-graph/energy-usage-summary',
  powerUsage: 'v0/custom-graph/power-usage',
  layeredPowerUsage: 'v0/layers/sensor-addons/power-usage',

  timeSeries: 'v0/custom-graph/readings/',
  customSummary: 'v0/custom-graph/summary/',
  customTimeSeries: 'v0/custom-graph/time-series/',
  layeredCustomTimeSeries: 'v0/layers/custom-graph/time-series/',

  airQuality:'v0/custom-graph/air-quality/'
};

const customWidgetEndpointLayers = {
  energyUseSummary: 'v0/layers/sensor-addons/energy-usage-summary',
  powerUsage: 'v0/layers/custom-graph/power-usage',
  layeredPowerUsage: 'v0/layers/sensor-addons/power-usage',

  timeSeries: 'v0/layers/custom-graph/readings/',
  customSummary: 'v0/layers/custom-graph/summary/',
  customTimeSeries: 'v0/layers/custom-graph/time-series/',
  layeredCustomTimeSeries: 'v0/layers/custom-graph/time-series/',

  airQuality:'v0/layers/custom-graph/air-quality/'
};

const machineStatusEndpoints = {
  instantaneous: 'v0/machine-status/instantaneous/',
  production: 'v0/machine-status/equipment/summary/',
  stack: 'v0/machine-status/status/summary/',
  getpost: 'v0/machine-status/level/',
  multiUpdate: 'v0/machine-status/level/multi-update/'
};
const machineStatusEndpointsLayers = {
  instantaneous: 'v0/machine-status/instantaneous/',
  production: 'v0/machine-status/equipment/summary/',
  stack: 'v0/machine-status/status/summary/',
  getpost: 'v0/machine-status/level/',
  multiUpdate: 'v0/machine-status/level/multi-update/'
};

export { widgetEndpoints, widgetEndpointsLayers,customWidgetEndpoints,customWidgetEndpointLayers, machineStatusEndpoints,machineStatusEndpointsLayers };
