import { useRef, useState, useEffect, Suspense } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import HomeLayoutComponent from '../../../modules/home/components/homelayout.component';
import DateRangeSet from '../../datetimepicker/daterangeset';
import useHttpApiCall from '../../../services/useHttpApiCall';
import ProcessLayoutComponent from '../../../modules/processdetail/components/processlayout.component';
import { processEnergyUseChartFilterButtons } from '../../../config/chartFilterButtons';
import { widgetEndpoints, widgetEndpointsLayers } from '../../../config/widgetEndpoints';
import widgetApiParams from '../../../utilities/widget.api.params';
import processEnergyUseChartConfig from '../../chartdataconfig/mainLayoutConfig/main.process.energy.use.chart.config';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import CompanyLayerLayoutComponent from '../../../modules/companyLayer/components/companyLayerlayout.component';
import PlantLayoutComponent from '../../../modules/plantdetail/components/plantlayout.component';

export default function MainProcessEnergyUseComponent(props) {
  const INITIALFILTER = '1week';
  const graphInfo = {
    isSummaryGraph: true
  }
  const EXTRATIME_TO_TRIGGER = 5 * 60 * 1000; //Refresh the api after 5 min
  // const [chartData, setChartData] = useState(undefined);
  const charRef = useRef(undefined);
  const location = useLocation();
  const navigate = useNavigate();
  // const [horScroll, setHorScroll] = useState(undefined);
  const [payload, setPayload] = useState(undefined);
  const [chartApiData, setChartApiData] = useState([]);
  const [xaxisLength, setXaxisLength] = useState(null);
  const [graphFilter, setGraphFilter] = useState({
    filterMain: INITIALFILTER
  });
  const [filterByDateCheck, setFilterByDateCheck] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [apiDateRange, setApiDateRange] = useState([]); //state for triggering the API only when date is set
  const onSidbarClick = useSelector((state) => state.sidebarClick.linkClick);
  const [apiParams, setApiParams] = useState(null);
  const [checkOfflineCut, setCheckOfflineCut] = useState(false);
  let setRefreshTimer, loadingTimer, errorTimer, lastUpdateWrongTimer;
  let [searchParams] = useSearchParams();
  const [isZoneEnabled, setIsZoneEnabled] = useState(searchParams.get('is_zone_enabled'));
  const [zoneId, setZoneId] = useState(null);
  const [isZoneLevel, setIsZoneLevel] = useState(false);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')

  let config;
  useEffect(() => {
    setApiParams(
      widgetApiParams(
        'ProcessEnergyUse',
        props,
        graphFilter,
        filterByDateCheck,
        apiDateRange,
        INITIALFILTER
      )
    );
  }, [graphFilter, apiDateRange, location.pathname]);

  useEffect(() => {
    setIsZoneLevel(false);
    setIsZoneEnabled(new URLSearchParams(location.search).get('is_zone_enabled'));
  }, [location.search]);

  // useEffect(() => {
  //  if(isZoneLevel){
  //   graphApiCall()
  //  }
  // }, [isZoneLevel])

  const graphApiCall = () => {
    if (isZoneEnabled) {
      if (zoneId) {
        graphZoneApiCall();
      } else {
        setPayload({
          auth: true,
          url: widgetEndpoints.processEnergyUseZoneLevel,
          params: apiParams
        });
      }
    } else {
      setPayload({
        auth: true,
        url: flexibleEquipmentEnabled=='true'?widgetEndpointsLayers?.processEnergyUse: widgetEndpoints.processEnergyUse,
        params: apiParams
      });
    }
  };

  const graphZoneApiCall = () => {
    setPayload({
      auth: true,
      url: widgetEndpoints.processEnergyUse,
      params: { ...apiParams, zone_id: zoneId }
    });
  };

  useEffect(() => {
    graphApiCall();
  }, [apiParams, zoneId]);

  useEffect(() => {
    setGraphFilter((prev) => ({
      ...prev,
      filterMain: INITIALFILTER
    }));
    setFilterByDateCheck(false);
  }, [onSidbarClick]);

  const [data, loading, error] = useHttpApiCall(payload);
  useEffect(()=>{
  },[props])
  useEffect(() => {
    if (data && data.data) {
      if(data?.data?.plant_code){
    localStorage.setItem('plantCode',data?.data?.plant_code)

      }

      if (data.data.data.yaxis_details && data.data.data.xaxis_categories) {
        let incompleteColorAddedData = data.data.data.yaxis_details.map((item) => ({
          ...item,
          color: item.incomplete_flag ? '#C4C4C4' : null
        }));
        let colorAddedData = { ...data.data.data, yaxis_details: [...incompleteColorAddedData] };
        setChartApiData(colorAddedData);
        setXaxisLength(data.data.data.xaxis_categories.length);
      } else {
        setChartApiData({ xaxis_categories: [], yaxis_details: [] });

        // setXaxisLength(null)
      }

      if (isZoneEnabled) {
        //Zone level is changed only if data is received.So Back Button appears only after chart draw.
        if (zoneId) {
          setIsZoneLevel(true);
        } else {
          setIsZoneLevel(false);
        }
      }
    }
  }, [data]);

  const resetZone = () => {
    setZoneId(null);
  };

  useEffect(() => {
    // if(chartApiData && chartApiData.xaxis_categories.length > 15){
    //   setHorScroll(1000)
    // }
  }, [chartApiData]);

  const graphFilterObject = processEnergyUseChartFilterButtons;

  config = {
    ...processEnergyUseChartConfig({
      chartApiData,
      graphFilter,
      props,
      location,
      navigate,
      xaxisLength,
      zoneId,
      setZoneId,
      setIsZoneLevel,
      isZoneLevel,
      graphName: props.graph_name,
      userAccessibility: props.userAccessibility
    })
  };

  const refreshGraphOnce = (diffrenceTime) => {
    let timeInMillisec;
    if (diffrenceTime > 0) {
      timeInMillisec = diffrenceTime.asMilliseconds() + EXTRATIME_TO_TRIGGER;
      console.log('will be called in milliseconds', timeInMillisec);

      setRefreshTimer = setTimeout(function () {
        setCheckOfflineCut(true);
        graphApiCall();
      }, timeInMillisec);
    } else {
      lastUpdateWrongTimer = setTimeout(
        function () {
          graphApiCall();
        },
        15 * 60 * 1000
      );
    }
  };

  useEffect(() => {
    if (!loading && checkOfflineCut) {
      loadingTimer = setTimeout(
        function () {
          graphApiCall();
        },
        15 * 60 * 1000
      );
    }
    return () => {
      clearTimeout(loadingTimer);
    };
  }, [loading]);

  useEffect(() => {
    if (error && !checkOfflineCut) {
      console.log('error case', error);
      errorTimer = setTimeout(
        function () {
          graphApiCall();
        },
        15 * 60 * 1000
      );
    }
    return () => {
      clearTimeout(errorTimer);
    };
  }, [error]);

  useEffect(() => {
    return () => {
      clearTimeout(setRefreshTimer);
      clearTimeout(lastUpdateWrongTimer);
    };
  }, []);

  useEffect(() => {
    if (data && data.data) {
      clearTimeout(setRefreshTimer);
      clearTimeout(lastUpdateWrongTimer);
      let lastUpdatedTime = moment(moment.utc(data.data.last_updated_time).toDate()).format('LLLL');
      let currentTime = moment();
      let timeForRefresh = moment(lastUpdatedTime, 'LLLL').add(1, 'days');
      let diffrenceTime = moment.duration(timeForRefresh.diff(currentTime));
      console.log('diff', diffrenceTime);
      setCheckOfflineCut(false);
      refreshGraphOnce(diffrenceTime);
    }
  }, [data]);

  return (
    <div>
      <Suspense fallback={<span>Loading</span>}>
        {
          props?.drilldownlevel == 'Company' ? (
            <CompanyLayerLayoutComponent
              chartApiData={chartApiData}
              loading={loading}
              chartData={config}
              charRef={charRef}
              expandWindow={props.expandWindow}
              minimizeWindow={props.minimizeWindow}
              shouldExpand={props.shouldExpand}
              chartId={props.id}
              hasFilter={true}
              graphFilter={graphFilter}
              setGraphFilter={setGraphFilter}
              graphFilterObject={graphFilterObject}
              hasSettings={false}
              filterByDate={true}
              setFilterByDateCheck={setFilterByDateCheck}
              filterByDateCheck={filterByDateCheck}
              dateRange={dateRange}
              setDateRange={setDateRange}
              hasIncompletePeriodLegend={true}
              isZoneLevel={isZoneLevel}
              setIsZoneLevel={setIsZoneLevel}
              resetZone={resetZone}
              graphInfo={graphInfo}
  
            />
          ) :
        props.drilldownlevel == 'Home' ? (
          <HomeLayoutComponent
            chartApiData={chartApiData}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            hasSettings={false}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            hasIncompletePeriodLegend={true}
            graphInfo={graphInfo}
          />
        ) : props.drilldownlevel == 'Process' ? (
          <ProcessLayoutComponent
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            hasSettings={false}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            hasIncompletePeriodLegend={true}
            isZoneLevel={isZoneLevel}
            setIsZoneLevel={setIsZoneLevel}
            resetZone={resetZone}
            graphInfo={graphInfo}

          />
        ) 
        
        :props.drilldownlevel == 'Plant'||props?.drilldownlevel=='ProcessLayer' ? (
          <PlantLayoutComponent
            chartApiData={chartApiData}
            loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            hasSettings={false}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            hasIncompletePeriodLegend={true}
            isZoneLevel={isZoneLevel}
            setIsZoneLevel={setIsZoneLevel}
            resetZone={resetZone}
            graphInfo={graphInfo}

          />
        ) : (
          ''
        )}
        <DateRangeSet
          graphFilter={graphFilter}
          setFilterByDateCheck={setFilterByDateCheck}
          filterByDateCheck={filterByDateCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setGraphFilter={setGraphFilter}
          initialFilter={INITIALFILTER}
          setApiDateRange={setApiDateRange}
          graphInfo={graphInfo}
        />
      </Suspense>
    </div>
  );
}
