// import moment from "moment";
import { TimeConvertion } from "./utc.time.convertion";

function machineStatusApiParams({ apiType, props, graphFilter, INITIALFILTER ,apiDateRange,summeryGraph}) {
  const filterMain =
    graphFilter &&
    (graphFilter.filterMain == ''
      ? { duration: INITIALFILTER }
      : { duration: graphFilter.filterMain });
  const processParams = {
    process_id: props.process_id,
    sub_process_id: props.sub_process_id
  };
  const machineParams = {
    process_id: props.process_id,
    sub_process_id: props.sub_process_id,
    equipment_id: props.equipment_id
  };
  const formatDate=(date)=>{
return date
    // return moment(date).format('YYYY-MM-DDTHH:MM:SS')
  }
//   const setFilterTime=(apiDateRange)=>{
//     const filterTime={ start_date_time:summeryGraph?formatDate( apiDateRange[0]) :moment.utc(apiDateRange[0]) , end_date_time: summeryGraph?formatDate( apiDateRange[1]) :moment.utc(apiDateRange[1]) }
// return filterTime
//   }

  switch (props.drilldownlevel) {
    case 'Process': {
      switch (apiType) {
        case 'production':
          switch (true) {
            case (apiDateRange?.length>0 &&  graphFilter && graphFilter.filterMain == '')?true:false:
              switch (apiDateRange?.length) {
                case 2:{
                const filterTime={ start_date_time:summeryGraph?formatDate( apiDateRange[0]) :TimeConvertion(apiDateRange[0]) , end_date_time: summeryGraph?formatDate( apiDateRange[1]) :TimeConvertion(apiDateRange[1]) }
                return{  ...processParams,...filterTime }
                }
                default:
                  break;
              }

              break;
          
            default:
              return { ...filterMain, ...processParams };
          }

          break
        case 'machineStatusGet':
          return { ...processParams };
      }
      break;
    }
    case 'Machine': {
      switch (apiType) {
        case 'production':
          switch (true) {
            case (apiDateRange?.length>0 &&  graphFilter && graphFilter.filterMain == '')?true:false:
              switch (apiDateRange?.length) {
                case 2:{
                const filterTime={ start_date_time:summeryGraph?formatDate( apiDateRange[0]) :TimeConvertion(apiDateRange[0]) , end_date_time: summeryGraph?formatDate( apiDateRange[1]) :TimeConvertion(apiDateRange[1]) }
                return{  ...filterMain,...filterTime,...machineParams }
                }
                default:
                  break;
              }

              break;
          
            default:
              return { ...filterMain, ...machineParams };
          }
          // return { ...filterMain, ... };
          break;
        case 'machineStatusGet':
          return { ...machineParams };
      }
      break;
    }
    default:
      return;
  }
}

export default machineStatusApiParams;
