import { useState, useEffect } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import ActiveAlertComponent from '../components/active.alerts.component';
export default function ActiveAlertsContainer({
  onAlertEdit,
  openAlertScreen,
  apiAlarmList,
  setApiAlarmList,
  modalRef,
  existingAlertMode,
  overlappingAlarmIds
}) {
  const [payload, setPayload] = useState(undefined);
  const [data] = useHttpApiCall(payload);
  const [payload2, setPayload2] = useState(undefined);
  const [data2] = useHttpApiCall(payload2);
  const [showDeleteToast, setShowDeleteToast] = useState(false);
  const [currentDeleteId, setCurrentDeleteId] = useState(null);

  //List active alarm API
  useEffect(() => {
    if (existingAlertMode) {
      setPayload({
        auth: true,
        url: `v0/alarms/`,
        params: { ids: overlappingAlarmIds }
      });
    } else {
      setPayload({
        auth: true,
        url: 'v0/alarms/'
      });
    }
  }, [data2,openAlertScreen.active_alerts]);

  useEffect(() => {
    if (data && data.data) {
      setApiAlarmList(data.data.data);
    }
  }, [data]);

  useEffect(() => {
    console.log(data2);
    if (data2) {
      setShowDeleteToast(true);
      setTimeout(() => {
        setShowDeleteToast(false);
      }, 3000);
      // if(duplicateMode){
      //     duplicateListApiCall()
      // }
    }
  }, [data2]);

  const onAlertDeleteConfirm = () => {
    setPayload2({
      auth: true,
      url: `v0/alarms/${currentDeleteId}/`,
      method: 'DELETE'
    });
  };

  const onAlertDelete = (alarmId) => {
    setCurrentDeleteId(alarmId);
  };

  return (
    <>
      <ActiveAlertComponent
        onAlertDelete={onAlertDelete}
        openAlertScreen={openAlertScreen}
        onAlertEdit={onAlertEdit}
        apiAlarmList={apiAlarmList}
        onAlertDeleteConfirm={onAlertDeleteConfirm}
        setShowDeleteToast={setShowDeleteToast}
        showDeleteToast={showDeleteToast}
        modalRef={modalRef}
        existingAlertMode={existingAlertMode}
      />
    </>
  );
}
