import React, { useRef, useState, useEffect } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { useNavigate } from 'react-router-dom';
import DateRangeSet from '../../../common/datetimepicker/daterangeset';
import {
  chartChartConfig,
  chartCommonConfig,
  chartTitleConfig,
  chartSubtitleConfig,
  chartYaxisConfig
} from '../../../config/chartCommon';
import { processEnergyUseChartFilterButtons } from '../../../config/chartFilterButtons';
import { widgetEndpoints } from '../../../config/widgetEndpoints';
import { useSelector } from 'react-redux';
import eventFire from '../../../utilities/eventFire-inside-highcharts';
import ReactGA from 'react-ga4';
import { roundToTwoDecimal } from '../../../utilities/common.computations';
import ProcessLayerComponent from '../components/processlayer.component';
export default function ProcessLayerContainer() {
  const INITIALFILTER = '1week';
  const graphInfo = {
    isSummaryGraph: true
  }
  const charRef = useRef(undefined);
  const navigate = useNavigate();
  const [payload, setPayload] = useState(undefined);
  const [chartApiData, setChartApiData] = useState(null);
  const [graphFilter, setGraphFilter] = useState({
    filterMain: INITIALFILTER
  });
  const [filterByDateCheck, setFilterByDateCheck] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [apiDateRange, setApiDateRange] = useState([]); //state for triggering the API only when date is set
  const userAccessibility = useSelector((state) => state.userAccessibility.set_user_accessibility);

  useEffect(() => {

    setPayload({
      auth: true,
      url: widgetEndpoints.processEnergyUse,
      //params:  graphFilter.filterSecondary ? { duration:graphFilter.filterMain,frequency:graphFilter.filterSecondary.key} : { duration:graphFilter.filterMain}
      params:
        graphFilter.filterMain != ''
          ? { duration: graphFilter.filterMain }
          : filterByDateCheck
          ? { usage_date_from: apiDateRange[0], usage_date_to: apiDateRange[1] }
          : { duration: INITIALFILTER }
    });
  }, [graphFilter, apiDateRange]);

  const [data] = useHttpApiCall(payload);
  useEffect(() => {
    if (data && data.data) {
      let incompleteColorAddedData = data.data.data.yaxis_details.map((item) => ({
        ...item,
        color: item.incomplete_flag ? '#C4C4C4' : null
      }));
      let colorAddedData = { ...data.data.data, yaxis_details: [...incompleteColorAddedData] };
      setChartApiData(colorAddedData);
    }
  }, [data]);

  const graphFilterObject = processEnergyUseChartFilterButtons;

 

  const config = {
    ...chartTitleConfig({
      graphTitle: 'Process energy use (kWh)',
      userAccessibility: userAccessibility
    }),
    ...chartSubtitleConfig({ chartApiData: chartApiData, userAccessibility: userAccessibility }),
    ...chartCommonConfig,
    chart: {
      className: 'process-energy-use',
      ...chartChartConfig({ userAccessibility: userAccessibility })
    },

    exporting: {
      chartOptions: {
        subtitle: {
          text: ''
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
              format: '{point.y:,.0f}'
            }
          }
        }
      }
    },

    plotOptions: {
      column: {
        cursor: 'pointer',
        color: '#71B2FF',
        point: {
          events: {
            click: function (data) {
              if (data.point.is_zone_enabled) {
                navigate(
                  `/equipment/${encodeURIComponent(data.point.category)}?process_type=${
                    data.point.process_type
                  }&process_id=${data.point.process_id}&sub_process_id=${
                    data.point.sub_process_id
                  }&is_zone_enabled=${data.point.is_zone_enabled}`
                );
                ReactGA.event({
                  category: 'Process bar clicked from process page',
                  action: 'Clicked Process Chart Bar - Process page',
                  label: `Clicked ${encodeURIComponent(data.point.category)}`
                });
              } else {
                navigate(
                  `/equipment/${encodeURIComponent(data.point.category)}?process_type=${
                    data.point.process_type
                  }&process_id=${data.point.process_id}&sub_process_id=${data.point.sub_process_id}`
                );
                ReactGA.event({
                  category: 'Process bar clicked from process page',
                  action: 'Clicked Process Chart Bar - Process page',
                  label: `Clicked ${encodeURIComponent(data.point.category)}`
                });
              }

              //  navigate(`/processes/${data.point.category}?process_type=${data.point.process_type}&process_id=${data.point.process_id}&sub_process_id=${data.point.sub_process_id}`)
            }
          }
        }
      }
    },
    xAxis: {
      categories: chartApiData && chartApiData.xaxis_categories,
      lineColor: '#cccccc',
      labels: {
        autoRotation: undefined,
        events: {
          click: function () {
            var xaxisLableElement = document.querySelectorAll(
              '.process-energy-use .highcharts-point'
            )[this.pos];
            eventFire(xaxisLableElement, 'click');
          }
        },
        style: {
          cursor: 'pointer'
        }
      },
      tickLength: 0
    },
    ...chartYaxisConfig({ userAccessibility }),

    tooltip: {
      useHTML:true,
      formatter: function () {
        let result;
        let co2Emmision = this.point.co2_emission ? `<div class="flex-c"><span class=" material-symbols-outlined notranslate-outlined tooltip-eco-icon pe-1">eco</span><span class="semi-bold">${roundToTwoDecimal(this.point.co2_emission)}&nbsp</span> kg CO₂</div>` : ''
        let commonTooltip = `<div class="txt-10">${
          this.point.name
        }</div><div class="flex-c"><span class="material-icons notranslate-outlined tooltip-bolt-icon">bolt</span><span><span  class="semi-bold">${parseInt(
          this.y
        )} kWh</div>${co2Emmision}<div style="font-size:11px">${this.point.start_end_dates[0]} &#8212; ${
          this.point.start_end_dates[1]
        }</div>`;
        let equipments = `<span class="txt-11" >${this.point.number_of_equipments} equipment</span>`;
        let incomplete = this.point.incomplete_flag
          ? `<span style="color:#C4C4C4;">\u25CF</span><span class=" txt-10"> Incomplete period </span>`
          : '';
        if (this.point.number_of_equipments >= 1) {
          result = `${commonTooltip}${equipments}  ${incomplete}`;
        } else {
          result = `${commonTooltip} ${incomplete}`;
        }
        return result;
      }
    },

    series: [
      {
        data: chartApiData && chartApiData.yaxis_details,

        // name:`Total: ${2010}kWh`,
        type: 'column'
        // tooltip:{
        //   pointFormat:`<div class="semi-bold">{point.y}</div><br><div style="font-size:11px">${chartApiData && chartApiData.start_end_dates && chartApiData.start_end_dates[0]} -- ${chartApiData &&  chartApiData.start_end_dates && chartApiData.start_end_dates[1]}</div>
        //   <br><div>${chartApiData && chartApiData.number_of_equipments} ${chartApiData && chartApiData.number_of_equipments > 1 ? 'equipments' : 'equipment'}<div>
        //   `,
        //     valueSuffix: ' kWh',
        //     valueDecimals: 0,
        // }
      }
    ]
  };

  return (
    <div>
      <ProcessLayerComponent
        chartData={config}
        charRef={charRef}
        chartApiData={chartApiData}
        hasFilter={true}
        graphFilter={graphFilter}
        setGraphFilter={setGraphFilter}
        graphFilterObject={graphFilterObject}
        hasSettings={false}
        filterByDate={true}
        setFilterByDateCheck={setFilterByDateCheck}
        filterByDateCheck={filterByDateCheck}
        dateRange={dateRange}
        setDateRange={setDateRange}
        hasIncompletePeriodLegend={true}
        graphInfo = {graphInfo}
      />
      <DateRangeSet
        graphFilter={graphFilter}
        setFilterByDateCheck={setFilterByDateCheck}
        filterByDateCheck={filterByDateCheck}
        dateRange={dateRange}
        setDateRange={setDateRange}
        setGraphFilter={setGraphFilter}
        INITIALFILTER={INITIALFILTER}
        setApiDateRange={setApiDateRange}
        graphInfo={graphInfo}

      />
    </div>
  );
}
