/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { Suspense, useEffect, useRef, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { machineStatusTimelineFilterButtons } from '../../../config/chartFilterButtons';
import { machineStatusEndpoints } from '../../../config/widgetEndpoints';
import MachineStatusLayoutComponent from '../components/machine.status.layout.component';
import machineStatusApiParams from '../../../utilities/machine.status.api.params';
import { useNavigate } from 'react-router-dom';
import timelineConfig from '../../../common/chartdataconfig/machineStatusConfig/timeline.config';
import useRealtimeUpdateGraph from '../../../hooks/useRealtimeUpdateGraph';
import moment from 'moment';
import DateRangeSet from '../../../common/datetimepicker/daterangeset';

export default function MachineStatusTimelineContainer(props) {
  const INITIALFILTER = '4hr';
  const charRef = useRef(undefined);
  const [chartApiData, setChartApiData] = useState(null);
  const [payload, setPayload] = useState(undefined);
  const [isMachineStatusEmpty, setIsMachineStatusEmpty] = useState(false);
  const [graphFilter, setGraphFilter] = useState({
    filterMain: INITIALFILTER
  });
  const [checkOfflineCut, setCheckOfflineCut] = useState(false);
  const [apiParams, setApiParams] = useState(null);
  const graphFilterObject = machineStatusTimelineFilterButtons;
  const [data, loading, error] = useHttpApiCall(payload);
  const [selectEquipments, setSelectEquipments] = useState(null);
  const navigate = useNavigate();
  const [chartUpdateTrigger, setChartUpdateTrigger] = useRealtimeUpdateGraph(null)



  const [filterByDateCheck, setFilterByDateCheck] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [apiDateRange, setApiDateRange] = useState([]);
  const is_operator = localStorage.getItem('is_operator') == 'true' ? true : false;

  const graphInfo = {
    isSummaryGraph: false
  }

  let loadingTimer,errorTimer;
  const graphApiCall = (type) => {
    setCheckOfflineCut(true)
    setPayload({
      auth: true,
      url: machineStatusEndpoints.instantaneous,
      params: apiParams
    });
    if(type != "update"){
      charRef.current.chart.zoomOut();
      charRef.current.chart.showLoading();
    }

  };
  const config = {
    ...timelineConfig({
      chartApiData,
      graphFilter,
      graphName: props.graph_name,
      userAccessibility: props.userAccessibility,
      props,
      navigate
    })
  };

  useEffect(() => {
    if (data && data.data) {
      let apiData = data.data.data;
      //if(chartApiData.length > 0){
        setCheckOfflineCut(false);
        setChartApiData(apiData);
        setChartUpdateTrigger(apiData.last_update_time)
        setIsMachineStatusEmpty(apiData.is_machine_status_empty);
        setSelectEquipments({
          equipment_length: apiData.equipments_count,
          is_equipment_filter_enabled: apiData.is_equipment_filter_enabled
        });
     // }
     // else{
      //  charRef.current.chart.series[0].setData([129.2, 144.0, 176.0, 135.6, 148.5, 216.4, 194.1, 95.6, 54.4, 29.9, 71.5, 106.4]);
      //}
      charRef.current.chart.hideLoading();
    }
  }, [data]);

  useEffect(() => {
    setApiParams(
      machineStatusApiParams({ apiType: 'production', props, graphFilter, INITIALFILTER ,apiDateRange})
    );
  }, [graphFilter, location.pathname,apiDateRange]);

  useEffect(() => {
    if (apiParams) {
      graphApiCall();
    }
  }, [apiParams,props.customizeUpdate.update]);

  const onSetFilterEquipments = () => {
    props.setOpenEquipmentFilterSlider(true);
    props.setFilterGraphId(props.graphId);
  };

  useEffect(() => {
    if (props.filterUpdate.filterGraphId == 3) {
      graphApiCall();
    }
  }, [props.filterUpdate.update]);


  useEffect(() => {
      graphApiCall("update")
  },[chartUpdateTrigger])
  
  useEffect(() => {
   console.log("checkOfflineCut",checkOfflineCut)
  }, [checkOfflineCut])
  
 
  useEffect(() => {
    // For API errors that dint hit the server
    if (!loading && checkOfflineCut) {
      console.log("loading false api call",loading)
      loadingTimer = setTimeout(function () {
        graphApiCall("update")
      }, 120000);
    }
    return () => {
      clearTimeout(loadingTimer);
    };
  }, [loading]);


  useEffect(() => {
    if (error) {
      console.log("machine error api call")

      errorTimer = setTimeout(function () {
        graphApiCall("update")
      }, 5000);
    }
    return () => {
      clearTimeout(errorTimer);
    };
  }, [error]);



  return (
    <div>
      <Suspense fallback={<span>Loading</span>}>
        <MachineStatusLayoutComponent
          chartApiData={chartApiData}
          loading={loading}
          chartData={config}
          charRef={charRef}
          expandWindow={props.expandWindow}
          minimizeWindow={props.minimizeWindow}
          shouldExpand={props.shouldExpand}
          chartId={props.id}
          hasFilter={true}
          graphFilter={graphFilter}
          setGraphFilter={setGraphFilter}
          graphFilterObject={graphFilterObject}
          hasSettings={!is_operator} //hide settings if its an operator account
          setOpenMachineStatusSlider={props.setOpenMachineStatusSlider}
          isMachineStatusEmpty={isMachineStatusEmpty}
          onSetFilterEquipments={onSetFilterEquipments}
          setOpenEquipmentFilterSlider={props.setOpenEquipmentFilterSlider}
          selectEquipments={selectEquipments}
          filterByDate={true}
          setFilterByDateCheck={setFilterByDateCheck}
          filterByDateCheck={filterByDateCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
          graphInfo={graphInfo}
        />
           <DateRangeSet
          graphFilter={graphFilter}
          setFilterByDateCheck={setFilterByDateCheck}
          filterByDateCheck={filterByDateCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setGraphFilter={setGraphFilter}
          initialFilter={INITIALFILTER}
          setApiDateRange={setApiDateRange}
          graphInfo={graphInfo}
        />
      </Suspense>
    </div>
  );
}
