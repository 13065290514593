import React, { useRef, useState, useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import HomeLayoutComponent from '../../../modules/home/components/homelayout.component';
import useHttpApiCall from '../../../services/useHttpApiCall';
import ProcessLayoutComponent from '../../../modules/processdetail/components/processlayout.component';
import MachineLayoutComponent from '../../../modules/machinedetail/components/machinelayout.component';
import { useLocation } from 'react-router';
import { lineInstantaneousChartFilterButtons } from '../../../config/chartFilterButtons';
import { widgetEndpoints, widgetEndpointsLayers } from '../../../config/widgetEndpoints';
import widgetApiParams from '../../../utilities/widget.api.params';
import instantaneousPowerChartConfig from '../../chartdataconfig/mainLayoutConfig/main.instantaneous.power.chart.config';
import RefreshSingleTimeseries from '../../chartautorefresh/refresh.single.timeseries';
import DateRangeSet from '../../datetimepicker/daterangeset';
import moment from 'moment';
import CompanyLayerLayoutComponent from '../../../modules/companyLayer/components/companyLayerlayout.component';
import PlantLayoutComponent from '../../../modules/plantdetail/components/plantlayout.component';

export default function MainInstantaneousPowerComponent(props) {
  const INITIALFILTER = '4hr';
  const graphInfo = {
    isSummaryGraph: false
  }
  // const [chartApiData, setChartApiData] = useState([]);
  const [chartApiData2, setChartApiData2] = useState([]);
  // const [payload, setPayload] = useState(undefined);
  const [payload2, setPayload2] = useState(undefined);
  const [payload3, setPayload3] = useState(undefined);
  const onSidbarClick = useSelector((state) => state.sidebarClick.linkClick);
  const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')

  const [apiParamsForInstantaneous, setApiParamsForInstantaneous] = useState(null);
  const [filterByDateCheck, setFilterByDateCheck] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  
  const [apiDateRange, setApiDateRange] = useState([]);
  // const [apiParamsForPeak, setApiParamsForPeak] = useState(null);
  const [lastTimeStamp, setLastTimeStamp] = useState(null);
  const location = useLocation();
  const charRef = useRef(undefined);
  const [graphFilter, setGraphFilter] = useState({
    filterMain: INITIALFILTER
  });
  const [minMaxValue, setMinMaxValue] = useState([]); // [min,max]

  const [peakvalue,setPeakVale]=useState({value:null,time:null})
  const graphFilterObject = lineInstantaneousChartFilterButtons;
  const graphTooltip = 'The power consumed at a particular point in time.';
  const isPlotedLine = true;
  const [config, setConfig] = useState({
    ...instantaneousPowerChartConfig(
      chartApiData2,
      graphFilter,
      props.graph_name,
      props.userAccessibility,
      graphTooltip,
      isPlotedLine,
      setMinMaxValue,
      // minMaxValue
    )
  });

  const graphInstantaneouwApiCall = () => {

    setPayload2({
      auth: true,
      url:flexibleEquipmentEnabled=='true'?widgetEndpointsLayers?.instantaneousPower: widgetEndpoints.instantaneousPower,
      params: apiParamsForInstantaneous
    });
    charRef?.current?.chart?.zoomOut();
    charRef?.current?.chart?.showLoading();
  };
  const graphAddPointApiCall = () => {
    let tempApiParamsForInstantaneous = { ...apiParamsForInstantaneous };
    const { duration, ...modifiedParams } = tempApiParamsForInstantaneous;
    console.log('duration', duration);
    setPayload3({
      auth: true,
      url: flexibleEquipmentEnabled=='true'?widgetEndpointsLayers?.instantaneousPower: widgetEndpoints.instantaneousPower,
      params: { ...modifiedParams, last_reading_time: lastTimeStamp }
    });
  };

  useEffect(() => {
    if (apiParamsForInstantaneous) {
      graphInstantaneouwApiCall();
    }
  }, [apiParamsForInstantaneous]);

  useEffect(() => {
    setApiParamsForInstantaneous(widgetApiParams('InstantaneousPower', props, graphFilter, filterByDateCheck,
      apiDateRange,
      INITIALFILTER));
  }, [graphFilter, apiDateRange, location.pathname]);

  useEffect(() => {
    setGraphFilter((prev) => ({
      ...prev,
      filterMain: INITIALFILTER,
      filterSecondary: graphFilterObject[INITIALFILTER].dropdown[0]
    }));
    charRef?.current?.chart?.zoomOut();
  }, [onSidbarClick]);


  const [data2] = useHttpApiCall(payload2);
  useEffect(() => {
    if (data2 && data2.data) {
      let apiData = data2.data.data;
      setConfig({
        ...instantaneousPowerChartConfig(
          apiData,
          graphFilter,
          props.graph_name,
          props.userAccessibility,
          graphTooltip,
          isPlotedLine,
      setMinMaxValue,
      props,


        )
      })
    }

  }, [data2])

  useEffect(() => {
    if (data2 && data2.data) {
      let apiData = data2.data.data;
      setChartApiData2(apiData);
      if (filterByDateCheck && (graphFilter.filterMain == null || graphFilter.filterMain == '')) {
        setLastTimeStamp(null);
        console.log("get in")

      }
      else {
        setLastTimeStamp(apiData.last_updated_time);

      }
      charRef?.current?.chart?.hideLoading(); // Remove this if peak load is present

    }
  }, [data2]);
  // const dmax=charRef?.current?.chart?.series[0]?.dataMax
  useEffect(()=>{
    if (data2?.data?.data) {
      if (charRef?.current?.chart?.xAxis[0]?.options?.plotLines[0]) {
        const index = charRef?.current?.chart?.series[0]?.yData?.indexOf(charRef.current.chart.series[0].dataMax)
        charRef.current.chart.xAxis[0].options.plotLines[0].value = charRef?.current?.chart?.series[0]?.xData[index];
        charRef.current.chart.xAxis[0].userOptions.title.text=`Peak: ${charRef.current.chart?.series[0]?.dataMax?.toFixed(2)} kW -  ${ moment.utc( charRef.current?.chart?.series[0]?.xData[index]).local().format('ddd, MMM D, HH:mm')}`
        isPlotedLine && charRef?.current?.chart?.xAxis[0]?.update();
        setPeakVale({
          value:charRef.current.chart.series[0].dataMax,
          time: moment.utc( charRef.current?.chart?.series[0]?.xData[index]).local().format('ddd, MMM D, HH:mm')
        })

      }
    }
  },[minMaxValue])

  useEffect(() => {
    if (data2?.data?.data) {
      if (charRef?.current?.chart?.xAxis[0]?.options?.plotLines[0]) {
        const index = charRef?.current?.chart?.series[0]?.yData?.indexOf(charRef.current.chart.series[0].dataMax)
        charRef.current.chart.xAxis[0].options.plotLines[0].value = charRef?.current?.chart?.series[0]?.xData[index];
        charRef.current.chart.xAxis[0].userOptions.title.text=`Peak: ${charRef.current.chart?.series[0]?.dataMax?.toFixed(2)} kW -  ${ moment.utc( charRef.current?.chart?.series[0]?.xData[index]).local().format('ddd, MMM D, HH:mm')}`
        isPlotedLine && charRef?.current?.chart?.xAxis[0]?.update();
        setPeakVale({
          value:charRef.current.chart.series[0].dataMax,
          time: moment.utc( charRef.current?.chart?.series[0]?.xData[index]).local().format('ddd, MMM D, HH:mm')
        })
      }
    }

  }, [props.expandWindow, chartApiData2])


  return (
    <div className='main-process-intantaneous-power'>
      <Suspense fallback={<span>Loading</span>}>
        {
           props.drilldownlevel == 'Company'  ? (
            <CompanyLayerLayoutComponent
              chartApiData={chartApiData2}
              // loading={loading}
              chartData={config}
              charRef={charRef}
              expandWindow={props.expandWindow}
              minimizeWindow={props.minimizeWindow}
              shouldExpand={props.shouldExpand}
              chartId={props.id}
              hasFilter={true}
              hasPeakLoadLegend={true}
              graphFilter={graphFilter}
              setGraphFilter={setGraphFilter}
              graphFilterObject={graphFilterObject}
              filterByDate={true}
              setFilterByDateCheck={setFilterByDateCheck}
              filterByDateCheck={filterByDateCheck}
              dateRange={dateRange}
              setDateRange={setDateRange}
              graphInfo={graphInfo}
              isPeakVisible={true}
              peakvalue={peakvalue}
  
            />
          ):
        props.drilldownlevel == 'Home' ? (
          <HomeLayoutComponent
            chartApiData={chartApiData2}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            hasPeakLoadLegend={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            graphInfo={graphInfo}
            isPeakVisible={true}
            peakvalue={peakvalue}
          />
        ) : props.drilldownlevel == 'Process' ? (
          <ProcessLayoutComponent
            chartApiData={chartApiData2}
            // loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            hasPeakLoadLegend={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            graphInfo={graphInfo}
            isPeakVisible={true}
            peakvalue={peakvalue}

          />
        ) : props.drilldownlevel == 'Plant'|| props.drilldownlevel=='ProcessLayer' ? (
          <PlantLayoutComponent
            chartApiData={chartApiData2}
            // loading={loading}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            hasPeakLoadLegend={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            filterByDate={true}
            setFilterByDateCheck={setFilterByDateCheck}
            filterByDateCheck={filterByDateCheck}
            dateRange={dateRange}
            setDateRange={setDateRange}
            graphInfo={graphInfo}
            isPeakVisible={true}
            peakvalue={peakvalue}

          />
        ) :
        
        props.drilldownlevel == 'Machine' ? (
          <MachineLayoutComponent
            chartApiData={chartApiData2}
            chartData={config}
            charRef={charRef}
            expandWindow={props.expandWindow}
            minimizeWindow={props.minimizeWindow}
            shouldExpand={props.shouldExpand}
            chartId={props.id}
            hasFilter={true}
            hasPeakLoadLegend={true}
            graphFilter={graphFilter}
            setGraphFilter={setGraphFilter}
            graphFilterObject={graphFilterObject}
            graphInfo={graphInfo}
            isPeakVisible={true}
            peakvalue={peakvalue}

          />
        ) : (
          ''
        )}

        {(filterByDateCheck && (graphFilter.filterMain == null || graphFilter.filterMain == '')) ? '' :
          <RefreshSingleTimeseries
            payload3={payload3}
            graphInstantaneouwApiCall={graphInstantaneouwApiCall}
            graphAddPointApiCall={graphAddPointApiCall}
            setChartApiData2={setChartApiData2}
            lastTimeStamp={lastTimeStamp}
            data2={data2}
            setLastTimeStamp={setLastTimeStamp}
            charRef={charRef}
            chartApiData={chartApiData2}
            filterByDateCheck={filterByDateCheck}
          />

        }
        <DateRangeSet
          graphFilter={graphFilter}
          setFilterByDateCheck={setFilterByDateCheck}
          filterByDateCheck={filterByDateCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setGraphFilter={setGraphFilter}
          initialFilter={INITIALFILTER}
          setApiDateRange={setApiDateRange}
          graphInfo={graphInfo}

        />
      </Suspense>
    </div>
  );
}
