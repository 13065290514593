/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import { Suspense, useEffect, useRef, useState } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { machineStatusStackFilterButtons } from '../../../config/chartFilterButtons';
import { machineStatusEndpoints } from '../../../config/widgetEndpoints';
import MachineStatusLayoutComponent from '../components/machine.status.layout.component';
import stackConfig from '../../../common/chartdataconfig/machineStatusConfig/stack.config';
import machineStatusApiParams from '../../../utilities/machine.status.api.params';
import { useNavigate } from 'react-router-dom';
import DateRangeSet from '../../../common/datetimepicker/daterangeset';
export default function MachineStatusStackContainer(props) {
  const INITIALFILTER = '1day';
  const charRef = useRef(undefined);
  const [chartApiData, setChartApiData] = useState([]);
  const [payload, setPayload] = useState(undefined);
  const [isMachineStatusEmpty, setIsMachineStatusEmpty] = useState(false);
  const [graphFilter, setGraphFilter] = useState({
    filterMain: INITIALFILTER
  });
  const [apiParams, setApiParams] = useState(null);
  const graphFilterObject = machineStatusStackFilterButtons;
  const [data, loading] = useHttpApiCall(payload);
  const [selectEquipments, setSelectEquipments] = useState(null);
  const [xAxisCategoryLables, setXAxisCategoryLables] = useState(null);


  const [filterByDateCheck, setFilterByDateCheck] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [apiDateRange, setApiDateRange] = useState([]);
  const summeryGraph=true
  const is_operator = localStorage.getItem('is_operator') == 'true' ? true : false;

  const graphInfo = {
    isSummaryGraph: true
  }
  const navigate = useNavigate();
  const graphApiCall = () => {
    setPayload({
      auth: true,
      url: machineStatusEndpoints.stack,
      params: apiParams
    });
  };
  const config = {
    ...stackConfig({
      chartApiData,
      xAxisCategoryLables,
      graphFilter,
      graphName: props.graph_name,
      userAccessibility: props.userAccessibility,
      props,
      navigate
    })
  };
  let initialCounterValue = 1;
  useEffect(() => {
    if (data && data.data) {
      let apiData = data.data.data;

      // let appendedSeries = [...apiData.machine_status]
      
      // appendedSeries.reverse().forEach((series,i) => {
      //   appendedSeries[i] = {...series, minPointLength: (i + 1) * 5}
      // })
      // console.log("appendedSeries", appendedSeries);
      // setChartApiData(appendedSeries.reverse());

       let dataSeries = apiData.machine_status.length > 0 ? [...apiData.machine_status[0].data] : [];
       let xAxisCategories = []
       dataSeries.forEach((series,i) => {
        xAxisCategories[i] = series.equipment_name;
      })
      setXAxisCategoryLables(xAxisCategories)
      setChartApiData(apiData.machine_status);

      setIsMachineStatusEmpty(apiData.is_machine_status_empty);
      setSelectEquipments({
        equipment_length: apiData.equipment_count,
        is_equipment_filter_enabled: apiData.is_equipment_filter_enabled
      });
    }
  }, [data]);


  useEffect(() => {
    setApiParams(
      machineStatusApiParams({ apiType: 'production', props, graphFilter, INITIALFILTER,apiDateRange ,summeryGraph})
    );
  }, [graphFilter, location.pathname,apiDateRange]);

  useEffect(() => {
    if (apiParams) {
      graphApiCall();
    }
  }, [apiParams,props.customizeUpdate.update]);

  const onSetFilterEquipments = () => {
    props.setOpenEquipmentFilterSlider(true);
    props.setFilterGraphId(props.graphId);
  };

  useEffect(() => {
    if (props.filterUpdate.filterGraphId == 2) {
      graphApiCall();
    }
  }, [props.filterUpdate.update]);

  return (
    <div>
      <Suspense fallback={<span>Loading</span>}>
        <MachineStatusLayoutComponent
          chartApiData={chartApiData}
          loading={loading}
          chartData={config}
          charRef={charRef}
          expandWindow={props.expandWindow}
          minimizeWindow={props.minimizeWindow}
          shouldExpand={props.shouldExpand}
          chartId={props.id}
          hasFilter={true}
          graphFilter={graphFilter}
          setGraphFilter={setGraphFilter}
          graphFilterObject={graphFilterObject}
          hasSettings={!is_operator} //hide settings if its an operator account
          setOpenMachineStatusSlider={props.setOpenMachineStatusSlider}
          isMachineStatusEmpty={isMachineStatusEmpty}
          onSetFilterEquipments={onSetFilterEquipments}
          setOpenEquipmentFilterSlider={props.setOpenEquipmentFilterSlider}
          selectEquipments={selectEquipments}
          filterByDate={true}
          setFilterByDateCheck={setFilterByDateCheck}
          filterByDateCheck={filterByDateCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
          graphInfo={graphInfo}
        />
           <DateRangeSet
          graphFilter={graphFilter}
          setFilterByDateCheck={setFilterByDateCheck}
          filterByDateCheck={filterByDateCheck}
          dateRange={dateRange}
          setDateRange={setDateRange}
          setGraphFilter={setGraphFilter}
          initialFilter={INITIALFILTER}
          setApiDateRange={setApiDateRange}
          graphInfo={graphInfo}
        />
      </Suspense>
    </div>
  );
}
