import { useState, useEffect, useContext, useRef } from 'react';
import EnterkpiComponent from '../components/enterkpi.component';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { context as KpiContext } from '../../../context/kpi.context';
import moment from 'moment';
import useOutsideAlerter from '../../../utilities/close.slider.outside.click';

export default function EnterkpiContainer({ setOpenKpiSlider, editKpiShow, setEditKpiShow }) {
  const [payload, setPayload] = useState(undefined);
  const [payload2, setPayload2] = useState(undefined);
  const [payload3, setPayload3] = useState(undefined);
  const [payload4, setPayload4] = useState(undefined);
  const [payload5, setPayload5] = useState(undefined);
  const [payload6, setPayload6] = useState(undefined);
  const [payload7, setPayload7] = useState(undefined);

  const [kpiApiData, setKpiApiData] = useState(undefined); //enter kpi get for prepopulating
  const [startDate, setStartDate] = useState(new Date());
  const [kpiMonthShow, setKpiMonthShow] = useState(moment(new Date()).format('MMMM'));
  const [kpiYearShow, setKpiYearShow] = useState(moment(new Date()).format('yyyy'));
  const [kpiMonth, setKpiMonth] = useState(moment(new Date()).format('MM'));
  const [formEdited, setFormEdited] = useState(false);
  const [customKpiFormEdited, setCustomKpiFormEdited] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [kpiScreen, setKpiScreen] = useState(false); // when false will show set kpi screen
  const [kpiListing, setKpiListing] = useState(undefined);
  const [kpiSelectedUnits, setKpiSelectedUnits] = useState([]); //To show units selected in set kpi section
  const [enterKpiList, setEnterKpiList] = useState([]); // get api for whitch kpi's are set against a company
  const [enterKpiListShow, setEnterKpiListShow] = useState([]); //To show data in enter kpi section
  const [editKpiData, setEditKpiData] = useState([]); // on selecting units , units are updated; on unselecting deleted attribute is added ; to update array for post and patch of set kpi
  const [showAddCustomKpi, setShowAddCustomKpi] = useState(false);
  const [enterKpiData, setEnterKpiData] = useState([]);

  var fetchKpiLinks = useContext(KpiContext).fetchKpiLinks;
  var kpiSharedList = useContext(KpiContext).state.kpiLinks;
  //console.log("inside enter kpi",moment("Jun 20", 'MMM yy').format('MMMM,yyyy'))

  const [data] = useHttpApiCall(payload);
  const [data2] = useHttpApiCall(payload2);
  const [data3] = useHttpApiCall(payload3);
  const [data4] = useHttpApiCall(payload4);
  const [data5] = useHttpApiCall(payload5);
  const [data6] = useHttpApiCall(payload6);
  const [data7] = useHttpApiCall(payload7);

  const [modalShow, setModalShow] = useState(false);

  var customKpiFormObject = {
    custom_kpi_name: {
      label: 'custom_kpi_name',
      value: '',
      name: 'custom_kpi_name'
    },
    custom_kpi_unit: {
      label: 'custom_kpi_unit',
      value: '',
      name: 'custom_kpi_unit'
    }
  };
  const [customApiDetails, setCustomApiDetails] = useState(customKpiFormObject);

  var initialFormObject = {
    produced_tonnes: {
      label: 'Produced units',
      value: '',
      name: 'produced_tonnes'
    },
    production_hours: {
      label: 'Production hours',
      value: '',
      name: 'production_hours'
    },
    turnover: {
      label: 'Turnover',
      value: '',
      name: 'turnover'
    },
    custom_kpi: {
      label: 'Custom KPI',
      value: '',
      name: 'custom_kpi'
    }
  };
  const [formData, setFormData] = useState(initialFormObject);

  useEffect(() => {
    if (kpiSharedList.openSetKpiMonth) {
      var date = moment(kpiSharedList.openSetKpiMonth, 'MMM YYYY').format('YYYY/MM');
      onMonthChange(new Date(date));
    }
  }, [kpiSharedList.openSetKpiMonth]);

  useEffect(() => {
    if (data4 && data4.data) {
      if (data4.data.data.length != 0) {
        if (editKpiShow) {
          setKpiScreen(false);
        } else {
          setKpiScreen(true);
        }
      } else {
        setKpiScreen(false);
      }
      setEnterKpiList(data4.data.data);
      setEnterKpiListShow(data4.data.data);
    }
  }, [data4]);

  //If KpiScreen is not set then call listing api
  useEffect(() => {
    setPayload5({
      auth: true,
      url: 'v0/kpis/'
    });
  }, []);

  useEffect(() => {
    if (data5 && data5.data) {
      setKpiListing(data5.data.data);
    }
  }, [data5]);

  // Api for getting the value for prepopulating in enter KPI
  useEffect(() => {
    setPayload({
      auth: true,
      url: 'v0/company/kpis/values/',
      params: { month: kpiMonth, year: kpiYearShow }
    });

    //Setting the kpi context to change kpi graphs
    // if(data6 || data7 || data2 || data3 ){
    //     console.log("confirm clicked")
    // var toggleBoolean = kpiSharedList.updateKpiGraph; //to change the state in kpi graph
    // kpiSharedList = {...kpiSharedList,"updateKpiGraph":toggleBoolean ? false : true}
    // fetchKpiLinks(kpiSharedList)
    // }
  }, [kpiYearShow, kpiMonth, data6, data7, data2, data3]);

  useEffect(() => {
    if (data2 || data3) {
      var toggleBoolean = kpiSharedList.updateKpiGraph; //to change the state in kpi graph
      kpiSharedList = { ...kpiSharedList, updateKpiGraph: toggleBoolean ? false : true };
      fetchKpiLinks(kpiSharedList);
    }
  }, [data2, data3]);

  useEffect(() => {
    if (data6 || data7) {
      var toggleBoolean = kpiSharedList.updateSetKpiGraph; //to change the state in kpi graph
      kpiSharedList = { ...kpiSharedList, updateSetKpiGraph: toggleBoolean ? false : true };
      fetchKpiLinks(kpiSharedList);
    }
  }, [data6, data7]);

  useEffect(() => {
    if (data && data.data) {
      setKpiApiData(data.data.data);
      //  if(!data.data.data[0]){
      //     setFormData(initialFormObject)
      //  }
    }
  }, [data, kpiYearShow, kpiMonth]);

  useEffect(() => {
    if (kpiApiData) {
      setFormEdited(false);
      let tempArray = [...enterKpiList];

      if (kpiApiData.length > 0) {
        kpiApiData.forEach(function (eachKpi) {
          let pos = enterKpiList
            .map(function (e) {
              return e.id;
            })
            .indexOf(eachKpi.company_kpi.id);
          tempArray[pos] = { ...tempArray[pos], value: eachKpi.value };
          setEnterKpiListShow(tempArray);
        });
      } else {
        tempArray = enterKpiList.map(function (o) {
          o.value = '';
          return o;
        });
        setEnterKpiListShow(tempArray);
      }
    }
  }, [kpiApiData, enterKpiList, kpiYearShow, kpiMonth]);

  useEffect(() => {
    console.log('kpiapidata', kpiApiData);
    if (kpiApiData) {
      let tempArray = [];

      kpiApiData.forEach(function (eachKpi) {
        let toPush = {
          id: eachKpi.id,
          company_kpi: eachKpi.company_kpi.id,
          value: eachKpi.value,
          kpi_date: eachKpi.kpi_date
        };
        tempArray.push(toPush);
      });
      setEnterKpiData(tempArray);
    }
  }, [kpiApiData]);

  const onMonthChange = (date) => {
    console.log(date);
    setStartDate(date);
    setKpiMonth(moment(date, 'MMMM yyyy').format('MM'));
    setKpiMonthShow(moment(date, 'MMMM yyyy').format('MMMM'));
    setKpiYearShow(moment(date, 'MMMM yyyy').format('yyyy'));
  };

  const inputHandler = (e, id) => {
    const { value } = e.target;
    let tempArray = [...enterKpiListShow];
    let index = enterKpiListShow
      .map(function (e) {
        return e.id;
      })
      .indexOf(id);
    if (value != '') {
      console.log('valuepresent');
      tempArray[index] = { ...tempArray[index], value: value };
    } else {
      console.log('valueabsent');
      delete tempArray[index]['value'];
    }
    setEnterKpiListShow(tempArray);

    let toPush;
    if (kpiApiData.length == 0) {
      toPush = {
        company_kpi: id,
        value: value,
        kpi_date: `${kpiYearShow}-${kpiMonth}-01`
      };
    } else {
      let pos = kpiApiData
        .map(function (e) {
          return e.company_kpi.id;
        })
        .indexOf(id);
      console.log(pos);
      if (pos < 0) {
        toPush = {
          company_kpi: id,
          value: value,
          kpi_date: `${kpiYearShow}-${kpiMonth}-01`
        };
      } else {
        toPush = {
          id: kpiApiData[pos].id,
          company_kpi: id,
          value: value,
          kpi_date: `${kpiYearShow}-${kpiMonth}-01`
        };
      }
    }

    if (enterKpiData.length == 0) {
      let enterKpiDataArray = [];
      if (value != '') {
        enterKpiDataArray.push(toPush);
        setEnterKpiData(enterKpiDataArray);
      }
    } else {
      let pos = enterKpiData
        .map(function (e) {
          return e.company_kpi;
        })
        .indexOf(id);
      if (pos < 0) {
        let enterKpiDataArray = [...enterKpiData];
        if (value != '') {
          enterKpiDataArray.push(toPush);
        }
        setEnterKpiData(enterKpiDataArray);
      } else {
        let enterKpiDataArray = [...enterKpiData];
        if (value != '') {
          enterKpiDataArray[pos] = { ...enterKpiDataArray[pos], value: toPush.value };
        } else {
          enterKpiDataArray.splice(pos, 1);
        }
        setEnterKpiData(enterKpiDataArray);
      }
    }
    setFormEdited(true);
  };

  useEffect(() => {
    console.log('customApiDetails', customApiDetails);
  }, [customApiDetails]);

  useEffect(() => {
    console.log('kpiSelectedUnits', kpiSelectedUnits);
  }, [kpiSelectedUnits]);

  useEffect(() => {
    console.log('kpiListing', kpiListing);
  }, [kpiListing]);

  useEffect(() => {
    console.log('enterKpiListShow', enterKpiListShow);
  }, [enterKpiListShow]);

  useEffect(() => {
    console.log('editKpiData', editKpiData);
  }, [editKpiData]);

  useEffect(() => {
    // Show preselected set kpi values in edit mode
    if (enterKpiList.length == 0) {
      setKpiSelectedUnits([]);
    } else {
      let toPush = [];
      let editKpiDataArray = [];
      enterKpiList.forEach(function (eachKpi) {
        toPush.push({
          kpi: eachKpi.kpi.id,
          kpi_unit: eachKpi.kpi_unit.id
        });

        if (eachKpi.kpi.is_custom) {
          setShowAddCustomKpi(true);
          const name = eachKpi.kpi.name;
          const unit = eachKpi.kpi_unit.name;
          editKpiDataArray.push({
            id: eachKpi.id,
            kpi: eachKpi.kpi.id,
            kpi_unit: eachKpi.kpi_unit.id,
            is_custom: true,
            custom_kpi_name: name,
            custom_kpi_unit_name: unit
          });

          setCustomApiDetails((prev) => ({
            ...prev,
            custom_kpi_name: { ...prev.custom_kpi_name, value: name },
            custom_kpi_unit: { ...prev.custom_kpi_unit, value: unit }
          }));
        } else {
          editKpiDataArray.push({
            id: eachKpi.id,
            kpi: eachKpi.kpi.id,
            kpi_unit: eachKpi.kpi_unit.id
          });
        }
      });
      setKpiSelectedUnits(toPush);
      setEditKpiData(editKpiDataArray);
    }
  }, [enterKpiList]);

  const customKpiInputHandler = (e) => {
    const { name, value } = e.target;
    setCustomKpiFormEdited(true);
    setCustomApiDetails((prev) => ({ ...prev, [name]: { ...prev[name], value } }));
  };

  const onUnselectClick = (event, kpi) => {
    // event.preventDefault();
    console.log(kpi);
    if (editKpiData.some((o) => o.kpi == kpi)) {
      editKpiData.find((o, i) => {
        if (o.kpi == kpi) {
          let updatedListEdit = [...editKpiData];
          console.log(kpi, i);
          updatedListEdit[i] = { ...updatedListEdit[i], deleted: true };
          setEditKpiData(updatedListEdit);
        }
      });
      let matchIndex = kpiSelectedUnits.findIndex((o) => o.kpi == kpi);
      if (matchIndex != -1) {
        let updatedListShow = [...kpiSelectedUnits];
        updatedListShow.splice(matchIndex, 1);
        setKpiSelectedUnits(updatedListShow);
      }
    }
  };

  const onUnitClick = (event, kpi, kpi_unit) => {
    event.preventDefault();

    let toPush = {
      kpi: kpi,
      kpi_unit: kpi_unit
    };

    if (editKpiData.some((o) => o.kpi == kpi)) {
      editKpiData.find((o, i) => {
        if (o.kpi == kpi) {
          let updatedList = [...editKpiData];
          updatedList[i] = { ...updatedList[i], kpi_unit: kpi_unit, deleted: false };
          setEditKpiData(updatedList);
        }
      });
    } else {
      setEditKpiData([...editKpiData, toPush]);
    }

    if (kpiSelectedUnits.some((o) => o.kpi == toPush.kpi)) {
      kpiSelectedUnits.find((o, i) => {
        if (o.kpi == toPush.kpi) {
          let updatedList = [...kpiSelectedUnits];
          updatedList[i] = toPush;
          setKpiSelectedUnits(updatedList);
        }
      });
    } else {
      setKpiSelectedUnits([...kpiSelectedUnits, toPush]);
    }
  };

  const onKpiSetSubmit = (e) => {
    e.preventDefault();

    if (enterKpiList.length == 0) {
      let postKpiDataForApi = [...kpiSelectedUnits];

      if (customApiDetails.custom_kpi_name.value || customApiDetails.custom_kpi_unit.value) {
        postKpiDataForApi = [
          ...postKpiDataForApi,
          {
            custom_kpi_name: customApiDetails.custom_kpi_name.value,
            custom_kpi_unit_name: customApiDetails.custom_kpi_unit.value
          }
        ];
      }

      //Confirm set api post for a company
      setPayload6({
        auth: true,
        url: 'v0/company/kpis/',
        method: 'POST',
        data: postKpiDataForApi
      });
    } else {
      //Edit set api post for a company
      setModalShow(true);
    }
  };

  const editSetKpiSubmit = () => {
    let editKpiDataForApi = [];
    editKpiDataForApi = editKpiData.filter(function (item) {
      return item.deleted != true || item.deleted == undefined;
    });

    if (editKpiDataForApi.some((o) => o.is_custom == true)) {
      editKpiDataForApi.forEach(function (eachKpi, i) {
        if (eachKpi.is_custom) {
          editKpiDataForApi[i] = {
            ...editKpiDataForApi[i],
            custom_kpi_name: customApiDetails.custom_kpi_name.value,
            custom_kpi_unit_name: customApiDetails.custom_kpi_unit.value
          };
          //  delete editKpiDataForApi[i]["is_custom"];
          if (
            customApiDetails.custom_kpi_name.value == '' &&
            customApiDetails.custom_kpi_unit.value == ''
          ) {
            editKpiDataForApi.splice(i, 1);
            console.log('editKpiDataForApi', editKpiDataForApi);
            setShowAddCustomKpi(false);
          } else {
            if (!customKpiFormEdited) {
              delete editKpiDataForApi[i]['custom_kpi_name'];
              delete editKpiDataForApi[i]['custom_kpi_unit_name'];
            } else {
              //if custom kpi form is edited then dont pass id
              delete editKpiDataForApi[i]['id'];
              delete editKpiDataForApi[i]['kpi'];
              delete editKpiDataForApi[i]['kpi_unit'];
            }
          }
        }
      });
    } else {
      if (customApiDetails.custom_kpi_name.value || customApiDetails.custom_kpi_unit.value) {
        editKpiDataForApi.push({
          custom_kpi_name: customApiDetails.custom_kpi_name.value,
          custom_kpi_unit_name: customApiDetails.custom_kpi_unit.value
        });
      }
    }

    console.log('editKpiData', editKpiData);

    console.log('editKpiDataForApi', editKpiDataForApi);
    console.log('enterKpiListShow', enterKpiListShow);
    setPayload7({
      auth: true,
      url: 'v0/company/kpis/multi-update/',
      method: 'PATCH',
      data: editKpiDataForApi
    });
  };

  useEffect(() => {
    if (data6 && data6.data) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setCustomKpiFormEdited(false);
      }, 3000);
    }
  }, [data6]);

  useEffect(() => {
    if (data7 && data7.data) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
        setCustomKpiFormEdited(false);
      }, 3000);
    }
  }, [data7]);

  // Api for getting if KPI's are set for this company
  useEffect(() => {
    setPayload4({
      auth: true,
      url: 'v0/company/kpis/'
    });
  }, [data6, data7, editKpiShow]);

  // Enter KPI form submit
  const onFormSubmit = (e) => {
    e.preventDefault();
    if (kpiApiData) {
      // Api for creating a editing row
      setPayload3({
        auth: true,
        url: `v0/company/kpis/values/multi-update/`,
        method: 'PATCH',
        data: enterKpiData,
        params: { month: kpiMonth, year: kpiYearShow }
      });
    } else {
      // Api for creating a new row
      setPayload2({
        auth: true,
        url: 'v0/company/kpis/values/',
        method: 'POST',
        data: enterKpiData
      });
    }
  };
  useEffect(() => {
    if (data2 && data2.data) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [data2]);

  useEffect(() => {
    if (data3 && data3.data) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
    }
  }, [data3]);

  const sliderRef = useRef(null);
  var isClickedOutside = useOutsideAlerter(sliderRef);
  useEffect(() => {
    if (isClickedOutside) {
      setOpenKpiSlider(false);
    }
  }, [isClickedOutside]);

  return (
    <div ref={sliderRef}>
      <EnterkpiComponent
        formData={formData}
        inputHandler={inputHandler}
        onFormSubmit={onFormSubmit}
        startDate={startDate}
        kpiMonthShow={kpiMonthShow}
        kpiYearShow={kpiYearShow}
        onMonthChange={onMonthChange}
        setOpenKpiSlider={setOpenKpiSlider}
        formEdited={formEdited}
        setShowToast={setShowToast}
        showToast={showToast}
        kpiScreen={kpiScreen}
        setKpiScreen={setKpiScreen}
        kpiListing={kpiListing}
        kpiSelectedUnits={kpiSelectedUnits}
        setKpiSelectedUnits={setKpiSelectedUnits}
        customKpiInputHandler={customKpiInputHandler}
        onKpiSetSubmit={onKpiSetSubmit}
        customApiDetails={customApiDetails}
        onUnitClick={onUnitClick}
        onUnselectClick={onUnselectClick}
        showAddCustomKpi={showAddCustomKpi}
        setShowAddCustomKpi={setShowAddCustomKpi}
        customKpiFormEdited={customKpiFormEdited}
        enterKpiList={enterKpiList}
        enterKpiListShow={enterKpiListShow}
        setEditKpiShow={setEditKpiShow}
        modalShow={modalShow}
        setModalShow={setModalShow}
        editSetKpiSubmit={editSetKpiSubmit}
        setFormData={setFormData}
      />
    </div>
  );
}
