const energyPriceTotalEnergyUseChartFilterButtons = {
  today: {
    name: 'Today',
    dropdown: []
  },

  '1week': {
    name: '1 w',
    dropdown: [],
    drilldown: '1day'
  },
  '1month': {
    name: '1 m',
    dropdown: [],
    drilldown: '1day'
  },
  '1year': {
    name: '1 y',
    dropdown: []
  },
  tomorrow: {
    name: 'Tomorrow',
    dropdown: []
  }
};

const totalEnergyUseChartFilterButtons = {
  '1day': {
    name: '24 h',
    dropdown: []
  },
  '1week': {
    name: '1 w',
    dropdown: [],
    drilldown: '1day'
  },
  '1month': {
    name: '1 m',
    dropdown: [],
    drilldown: '1day'
  },
  '3months': {
    name: '3 m',
    dropdown: []
  },
  '1year': {
    name: '1 y',
    dropdown: []
    // "disabled":true
  }
};

const processEnergyUseChartFilterButtons = {
  '1week': {
    name: '1 w',
    dropdown: []
  },
  '1month': {
    name: '1 m',
    dropdown: []
  },
  '3months': {
    name: '3 m',
    dropdown: []
  },
  '1year': {
    name: '1 y',
    dropdown: []
    // "disabled":true
  }
};

const customEnergySummaryChartFilterButtons = totalEnergyUseChartFilterButtons;

const lineInstantaneousChartFilterButtons = {
  '4hr': {
    name: 'Last 4hr',
    dropdown: []
  },
  '1day': {
    name: '1 d',
    dropdown: [
      { key: 'without_sampling', value: 'w/o sampling' },
      { key: '1hr', value: '1 Hour' },
      { key: '4hr', value: '4 Hour' }
    ]
  },
  '1week': {
    name: '1 w',
    dropdown: [
      { key: 'without_sampling', value: 'w/o sampling' },
      { key: 'halfday', value: '1/2 Day' },
      { key: '1day', value: '1 Day' }
    ]
  },
  '1month': {
    name: '1 m',
    dropdown: [
      { key: 'without_sampling', value: 'w/o sampling' },
      { key: '1week', value: '1 Week' },
      { key: '4week', value: '4 Week' }
    ]
  }
  // "1year":{
  //   "name": "1 y",
  //   "disabled":true
  // }
};

const lineProcessInstantaneousChartFilterButtons = {
  '4hr': {
    name: 'Last 4hr',
    dropdown: []
  },
  '1day': {
    name: '1 d',
    dropdown: []
  },
  '1week': {
    name: '1 w',
    dropdown: []
  },
  '1month': {
    name: '1 m',
    dropdown: [
      { key: 'without_sampling', value: 'w/o sampling' },
      { key: '1week', value: '1 Week' },
      { key: '4week', value: '4 Week' }
    ]
  }
  // "1year":{
  //   "name": "1 y",
  //   "disabled":true
  // }
};

const lineTempAndHumChartFilterButtons = {
  '1day': {
    name: '1 d',
    dropdown: []
  },
  '1week': {
    name: '1 w',
    dropdown: []
  },
  '1month': {
    name: '1 m',
    dropdown: []
  },
  '3months': {
    name: '3 m',
    dropdown: []
  }
};

const machineKpiTotalGraphFilterButtons = {
  '1month': {
    name: '1 m',
    dropdown: []
  },
  '3months': {
    name: '3 m',
    dropdown: []
  },
  '1year': {
    name: '1 y',
    dropdown: []
  }
};

const machineStatusProductionFilterButtons = {
  '1week': {
    name: '1 w',
    dropdown: []
  },
  '1month': {
    name: '1 m',
    dropdown: []
  },
  '1year': {
    name: '1 y',
    dropdown: []
  }
};

const machineStatusStackFilterButtons = {
  '1day': {
    name: '1 d',
    dropdown: []
  },
  '1week': {
    name: '1 w',
    dropdown: []
  },
  '1month': {
    name: '1 m',
    dropdown: []
  },
  '1year': {
    name: '1 y',
    dropdown: []
  }
};

const machineStatusTimelineFilterButtons = {
  '4hr': {
    name: 'Last 4hr',
    dropdown: []
  },
  '1day': {
    name: '1 d',
    dropdown: []
  },
  '1week': {
    name: '1 w',
    dropdown: []
  },
  '1month': {
    name: '1 m',
    dropdown: []
  },
};

const incomersAdnGeneratorsFilterButtons = {
  '1day': {
    name: '24 h',
    dropdown: []
  },
  '1week': {
    name: '1 w',
    dropdown: [],
    drilldown: '1day'
  },
  '1month': {
    name: '1 m',
    dropdown: [],
    drilldown: '1day'
  },
  '3months': {
    name: '3 m',
    dropdown: []
  },
  '1year': {
    name: '1 y',
    dropdown: []
    // "disabled":true
  }
};

export {
  customEnergySummaryChartFilterButtons,
  processEnergyUseChartFilterButtons,
  totalEnergyUseChartFilterButtons,
  lineInstantaneousChartFilterButtons,
  lineProcessInstantaneousChartFilterButtons,
  lineTempAndHumChartFilterButtons,
  energyPriceTotalEnergyUseChartFilterButtons,
  machineKpiTotalGraphFilterButtons,
  machineStatusProductionFilterButtons,
  machineStatusStackFilterButtons,
  machineStatusTimelineFilterButtons,
  incomersAdnGeneratorsFilterButtons
};
