/* eslint-disable no-unused-vars */
import { useState, useEffect, useRef } from 'react';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { Alert } from 'react-bootstrap';
import ActiveAlertsContainer from '../containers/active.alerts.container';
import SelectSourceContainer from '../containers/select.source.container';
import SetAlertsContainer from '../containers/set.alerts.container';
import SelectSensorClassificationContainer from '../containers/select.sensor.classification.container';
import { useSelector } from 'react-redux';
import useOutsideAlerter from '../../../utilities/close.slider.outside.click';
import AlertTypeContainer from '../containers/alert.type.container';

export default function AlertsComponent({
  openAlertSlider,
  openAlertScreen,
  checkedItems,
  setCheckedItems,
  alertMenuClicked
}) {
  const [showToast, setShowToast] = useState(false);
  const [payload, setPayload] = useState(undefined);
  const [data] = useHttpApiCall(payload); //API for listing all the equipments with id and name
  const [apiEquipmentList, setApiEquipmentList] = useState([]);
  const [payload2, setPayload2] = useState(undefined);
  const [data2] = useHttpApiCall(payload2);
  const [payload5, setPayload5] = useState(undefined);
  const [data5] = useHttpApiCall(payload5); //API for edit from equipment drilldown
  const [payload6, setPayload6] = useState(undefined);
  const [data6] = useHttpApiCall(payload6); //API for create from equipment drilldown
  const [payload7, setPayload7] = useState(undefined);
  const [data7] = useHttpApiCall(payload7); //API can be used to get details for listing the sensor types
  const [apiEquipmentEdit, setApiEquipmentEdit] = useState(undefined);
  const [editMode, setEditMode] = useState(false);
  const [apiAlarmList, setApiAlarmList] = useState([]);
  const [sensorTypeList, setSensorTypeList] = useState([]);
  const [selectSensorClassification, setSelectSensorClassification] = useState(false);
  const [alertTypeEnabled, setAlertTypeEnabled] = useState(null);
  const [sensorUnit, setSensorUnit] = useState(null);
  const [selectedAlertType, setSelectedAlertType] = useState(null);
  const [directEquipmentEdit, setDirectEquipmentEdit] = useState(false);
  const [directEquipmentCreate, setDirectEquipmentCreate] = useState(false);
  const [directEquipmentCreateList, setDirectEquipmentCreateList] = useState(undefined);
  const [existingAlertMode, setExistingAlertMode] = useState(false);
  const [overlappingAlarmIds, setOverlappingAlarmIds] = useState(false);
  const [isSelectAlertType, setIsSelectAlertType] = useState(false);
  const [equipmentId,setEquipmentId]=useState(1)
  const sliderRef = useRef(null);
  const modalRef = useRef(null);
  const executeScrollTop = () => {
    sliderRef.current.scrollTo(0, 0);
  };
  var isClickedOutside = useOutsideAlerter(sliderRef);
  var clickOusidemodal = useOutsideAlerter(modalRef);
  const is_temp_humidity_classification_enabled = useSelector(
    (state) => state.company.sensor_classification
  );

  useEffect(() => {
    console.log(clickOusidemodal, isClickedOutside, modalRef, sliderRef);
    if (isClickedOutside) {
      openAlertScreen('close_slider');
    }
  }, [isClickedOutside]);

  useEffect(() => {
    console.log(clickOusidemodal, isClickedOutside, modalRef, sliderRef);
    if (isClickedOutside) {
      openAlertScreen('close_slider');
    }
  }, [isClickedOutside]);

  var filterEquipment;
  useEffect(() => {
    console.log(
      'enter screen',
      is_temp_humidity_classification_enabled,
      openAlertSlider.select_source
    );
    if (is_temp_humidity_classification_enabled && openAlertSlider.select_source) {
      setPayload7({
        auth: true,
        url: 'v0/equipments/types/',
        params: { exclude_water_flow: 1 }
      });
    } else {
      selectEquipmentAPICall(equipmentId);
    }
  }, [openAlertSlider.select_source]);

  useEffect(() => {
    if (data && data.data) {
      setApiEquipmentList(data.data.data);
      if (!directEquipmentEdit) {
        setEditMode(false);
      }
    }
  }, [data]);

  const selectEquipmentAPICall = (id) => {
setEquipmentId(id)
    setPayload({
      auth: true,
      url: 'v0/equipments/',
      params: { equipment_type: id }
    });
  };
  useEffect(() => {
    if (data7 && data7.data) {
      console.log('show equipement classification', data7.data.data);
      setSensorTypeList(data7.data.data);
    }
  }, [data7]);

  useEffect(() => {
    if (data2 && data2.data) {
      console.log('edit data', data2.data.data);
      setEditMode(true);
      setApiEquipmentEdit(data2.data.data);
      openAlertScreen('set_alerts');
    }
  }, [data2]);

  const onAlertEdit = (alarmId) => {
    //Edit alarm API
    setPayload2({
      auth: true,
      url: `v0/alarms/${alarmId}/`
    });
  };

  useEffect(() => {
    if (openAlertSlider.select_source) {
      setEditMode(false);
    }
  }, [openAlertSlider]);

  //Duplicating of data
  const onSourceNextClick = () => {
    openAlertScreen('set_alerts');
  };

  const equipment_combination_id = useSelector((state) => state.equipmentAlert.combination_id);

  useEffect(() => {
    if (equipment_combination_id) {
      openAlertScreen('set_alerts');

      filterEquipment = {
        process: equipment_combination_id.split('_')[0],
        sub_process_id: equipment_combination_id.split('_')[1],
        equipment_id: equipment_combination_id.split('_')[2]
      };
      setPayload5({
        auth: true,
        url: 'v0/alarms/',
        params: filterEquipment
      });
      setPayload6({
        auth: true,
        url: 'v0/equipments/',
        params: filterEquipment
      });
    }
  }, [equipment_combination_id]);

  useEffect(() => {
    if (data5 && data5.data.data) {
      if (data5.data.data.length > 0) {
        setDirectEquipmentEdit(true);
        setDirectEquipmentCreate(false);
        setEditMode(true);
        setApiEquipmentEdit(data5.data.data[0]);
      } else {
        setDirectEquipmentEdit(false);
        setDirectEquipmentCreate(true);
      }
    }
  }, [data5]);

  useEffect(() => {
    if (data6 && data6.data) {
      setDirectEquipmentCreateList(data6.data.data[0]);
    }
  }, [data6]);

 useEffect(() => {
  console.log("menu clicked")
  setAlertTypeEnabled(false);
  setIsSelectAlertType(false)
  setSelectSensorClassification(false)
  
 }, [alertMenuClicked])
 


  const AlertScreenSelect = () => {
    if (openAlertSlider.select_source) {
      if (is_temp_humidity_classification_enabled && !selectSensorClassification ) {
        return (
          <SelectSensorClassificationContainer
            sensorTypeList={sensorTypeList}
            selectEquipmentAPICall={selectEquipmentAPICall}
            setSelectSensorClassification={setSelectSensorClassification}
            setCheckedItems={setCheckedItems}
            setSensorUnit={setSensorUnit}
            setApiEquipmentList={setApiEquipmentList}
            setAlertTypeEnabled ={setAlertTypeEnabled}
          />
        );
      } else if((is_temp_humidity_classification_enabled && alertTypeEnabled && !isSelectAlertType  ) || (!is_temp_humidity_classification_enabled && !isSelectAlertType) ){
        return (
        <AlertTypeContainer openAlertScreen={openAlertScreen} setSelectSensorClassification={setSelectSensorClassification} selectSensorClassification ={ selectSensorClassification} setIsSelectAlertType={setIsSelectAlertType} setSelectedAlertType={setSelectedAlertType}/>
        );
      }
      else{
        return (
            <SelectSourceContainer
              setShowToast={setShowToast}
              openAlertScreen={openAlertScreen}
              setCheckedItems={setCheckedItems}
              checkedItems={checkedItems}
              apiEquipmentList={apiEquipmentList}
              onSourceNextClick={onSourceNextClick}
              setSelectSensorClassification={setSelectSensorClassification}
              setIsSelectAlertType={setIsSelectAlertType}
              isSelectAlertType={isSelectAlertType}
            />
          );
      }
    } else if (openAlertSlider.set_alerts) {
      return (
        <SetAlertsContainer
          setShowToast={setShowToast}
          openAlertScreen={openAlertScreen}
          checkedItems={checkedItems}
          apiEquipmentList={apiEquipmentList}
          apiEquipmentEdit={apiEquipmentEdit}
          editMode={editMode}
          setEditMode={setEditMode}
          alertEditApiResponse={data2}
          executeScrollTop={executeScrollTop}
          directEquipmentEdit={directEquipmentEdit}
          directEquipmentCreate={directEquipmentCreate}
          directEquipmentCreateList={directEquipmentCreateList}
          existingAlertMode={existingAlertMode}
          setExistingAlertMode={setExistingAlertMode}
          setOverlappingAlarmIds={setOverlappingAlarmIds}
          sensorUnit={sensorUnit}
          isTempHumidityClassificationEnabled={is_temp_humidity_classification_enabled}
          selectedAlertType={selectedAlertType}
          isSelectAlertType={isSelectAlertType}
        />
      );
    } else if (openAlertSlider.active_alerts) {
      return (
        <ActiveAlertsContainer
          setShowToast={setShowToast}
          openAlertScreen={openAlertScreen}
          onAlertEdit={onAlertEdit}
          checkedItems={checkedItems}
          apiAlarmList={apiAlarmList}
          setApiAlarmList={setApiAlarmList}
          modalRef={modalRef}
          existingAlertMode={existingAlertMode}
          overlappingAlarmIds={overlappingAlarmIds}
        />
      );
    }
    // else if (openAlertSlider.alert_type) {
    //   return (
    //     <AlertTypeContainer openAlertScreen={openAlertScreen} setSelectSensorClassification={setSelectSensorClassification} />
    //   );
    // } 
    else {
      return null;
    }
  };

  return (
    <div>
      <div ref={sliderRef} className="sidebar-slider alert-slider">
        <Alert
          className="sidebar-slider_alert"
          show={showToast}
          variant="success"
          onClose={() => setShowToast(false)}>
          <p className="text-center">
            {editMode ? 'Alert updated successfully' : 'Alert created successfully'}
          </p>
        </Alert>
        <i
          className="sidebar-slider_close material-icons notranslate graphoptionicons"
          onClick={() => openAlertScreen('close_slider')}>
          cancel
        </i>
        {AlertScreenSelect()}
      </div>
    </div>
  );
}
