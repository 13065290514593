/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export default function SidebarLayerList({dataList,toggleActiveClass,kpi_view,energy_view}){
  const [openMenu, setOpenMenu] = useState(null);
  const [openNestedMenu, setOpenNestedMenu] = useState(null);
  const [openNestedChildMenu, setOpenNestedChildMenu] = useState(null);

  const toggleMenu = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu);
  };

  const toggleNestedMenu = (menu) => {
    setOpenNestedMenu(openNestedMenu === menu ? null : menu);
  };

  const toggleNestedChildMenu = (menu) => {
    setOpenNestedChildMenu(openNestedChildMenu === menu ? null : menu);
  };

 return(
    <>
   <div className="sidebar">
   {dataList.map((plantMenu, i) => {
    // debugger
  // `/plant/${encodeURIComponent(data.point.category)}?process_type=${
  //                         data.point.layer_type
  //                       }&process_id=${data.point.layer_id}`
  // `/plant/${encodeURIComponent(data.point.category)}?process_type=${
  //   data.point.layer_type
  // }&process_id=${data.point.layer_id}`
return(
  <>
    <div className="menu-item" onMouseEnter={() => toggleMenu('menu1')} onMouseLeave={() => toggleMenu(null)}>
      {/* ========================plant=================================== */}
        <NavLink to={`/${plantMenu.layer_type}/${encodeURIComponent(plantMenu?.name)}?process_type=${plantMenu.layer_type}&plant_id=${plantMenu?.layer_id}&plant_code=${plantMenu?.plant_code}`} activeClassName="active">{plantMenu?.name}</NavLink>
        {openMenu === 'menu1' &&plantMenu?.layers&& (
          <div className="child-menu shadow">
             { plantMenu?.layers?.map((processMenu, i) => {

return(<>
 <div className="child-item " onMouseEnter={() => toggleNestedMenu('nested1')} onMouseLeave={() => toggleNestedMenu(null)}>
      {/* ========================process=================================== */}

              <NavLink
               to={`/layer_process/${processMenu?.name}?process_type=${processMenu.layer_type}&process_id=${processMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&plant_id=${plantMenu?.layer_id}`}
                activeClassName="active" className="child-link">{processMenu?.name}</NavLink>
              {/* <i class="material-icons notranslate graphoptionicons red-color">notifications</i> */}
          
              {openNestedMenu === 'nested1' && (
                <div className="nested-child-menu shadow" onMouseEnter={() => toggleNestedChildMenu('nestedChild1')} >
                  { processMenu?.layers?.map((equipmentMenu, i) => {
                  return(<>
      {/* ========================equipment=================================== */}

                   <NavLink 
                   to={`/layer_equipment/${processMenu?.name}?/${encodeURIComponent(equipmentMenu?.name)}?process_type=${equipmentMenu.layer_type}&equipment_id=${equipmentMenu?.layer_id}&plant_code=${plantMenu?.plant_code}&process_id=${processMenu?.layer_id}`}
                   activeClassName="active" className="nested-child-link">{equipmentMenu?.name}</NavLink>
           
                   {openNestedChildMenu === 'nestedChild1' && (
                    <div className="nested-child-child-menu shadow">
                       { equipmentMenu?.layers?.map((componentMenu, i) => {
return(<>
                      <NavLink to={`/${componentMenu.layer_type}/${encodeURIComponent(componentMenu?.name)}?process_type=${componentMenu.layer_type}&process_id=${componentMenu?.layer_id}&plant_code=${plantMenu?.plant_code}`} activeClassName="active" className="nested-child-child-link">{componentMenu?.name}</NavLink>

</>)
                       })}
                      {/* <NavLink to="/parent1/child1/nested1/child2" activeClassName="active" className="nested-child-child-link">Nested Child 1.2</NavLink> */}
                    </div>
                  )}
                   </>
                 )

                  })}
               
                </div>
              )}
            </div>
</>)
             })}
           
          </div>
        )}
      </div>
  </>
)
   })}
    
    
      {/* Add more menu items as needed */}
    </div>
    </>
  
    )
  }